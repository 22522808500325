.App {
  /* Warum text-align: center; ? */
  height: 100vh;
}

.App-header {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  margin-top: 4rem;
  margin-left: 12em;
  width: 80%;
}

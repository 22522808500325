/* Bootstrap Variablen hier überschreiben */
:root {
  --background-color: #282c34;
  --sidebar-hover-color: #7f7f80;

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #EF6C00;
  --toastify-color-error: #e74c3c;
}

body {
  background-color: #f7f7f7 !important;
  height: 100vh;
}

@import "variables";

@import "../../../node_modules/bootstrap/scss/bootstrap";

/* Globale Styles hier */
.dropdown-menu.show.dropdown-menu-end {
  @extend .shadow;
  @extend .border-0;
  z-index: 50;
}

.ps-sidebar-root { /* FIX right border  */
  border-right: none !important;

  background: rgb(2,0,36);
  background: linear-gradient(347deg, rgba(2,0,36,1) 0%, rgba(10,10,131,1) 35%, rgb(0 212 255 / 71%) 100%);
}

.ps-sidebar-container {
  opacity: 0.85;
}